
@import "@/styles/_variables.scss";

.fair-product-category{
    display: flex;

    .v-select { max-width: 280px;}
    ::v-deep .v-input__slot { margin-bottom: 0; background-color: #fff; }

    &__col:not(:last-child) { position: relative; padding-right: 45px; }
    &__col:not(:last-child)::after {
        content: ''; position: absolute; top: 0; right: 0; bottom: 0;
        margin: auto; width: 45px; height: 45px; background: url('/images/icon/category-arrow-right.png') no-repeat center / auto 10px;
    }
}
@media(max-width:1200px){
    
}
@media(max-width:768px){
    .fair-product-category{
        flex-direction: column;

        .v-input { font-size: 14px; }
        .v-select { max-width: none; }
        &__col { padding-top: 4px; padding-bottom: 4px; padding-right: 35px; }
        &__col:not(:last-child) { padding-right: 35px; }
        &__col:not(:last-child)::after { width: 35px; height: 35px; }
    }
}
