
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.list-option{
    &__primary { padding: 24px 20px; background: #f9f9f9; border-radius: 10px; }
    &__primary .col { padding-top: 0; padding-bottom: 0; }
    &__secondary { padding: 24px 20px; }
    &__secondary .col { padding-top: 0; padding-bottom: 0; }
    &__secondary .col:last-child { display: flex; flex-direction: column; align-items: flex-end; }
    &__secondary .v-input { max-width: 280px; }
}
::v-deep .fair-product{
    &__head{
        display: flex; justify-content: space-between; padding: 20px 0; font-size: 14px; color: #999999;

        .tit { line-height: 1; font-size: 18px; }
    }
    &__body { border-top: 1px solid #999999; }
    &__contents { display: flex; flex-wrap: wrap; margin: 0 -12px; }
    &__list { flex: 0 0 20%; padding: 22px 12px; width: 20%; font-size: 14px; color: #999999; transition: all ease .5s; }
    &__list-thumb { overflow: hidden; position: relative; margin-bottom: 25px; padding-top: 100%; width: 100%; height: 0; border: 1px solid $border; border-radius: 15px; }
    &__list-thumb img { display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; transition: all ease .5s; }
    &__list-txt { @include line-clamp(1); margin: 0 10px; line-height: 1; font-size: 16px; color: #666; }
    &__list-tit { @include line-clamp(2); margin: 10px 10px 0; font-size: 18px; color: #222; font-weight: 700; }
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; line-height: 1; }
}
@media(min-width:1201px){
    .fair-product{
       ::v-deep  &__list-inner:hover &__list-thumb img { transform: scale(1.1); }   
    }
}
@media(max-width:1200px){
    ::v-deep .fair-product{
        &__head{
            .tit { line-height: 1; font-size: 17px; }
        }
        &__list { flex: 0 0 25%; width: 25%; }
        &__list-txt { font-size: 15px; }
        &__list-tit { font-size: 17px; }
    }
}
@media(max-width:768px){
    .list-option{
        &__secondary { padding: 12px 0 0; }
        &__secondary .v-input { max-width: none; }
        &__secondary .col { flex-basis: 100%; padding-top: 4px; padding-bottom: 4px; }
        &__secondary .col:last-child { flex-direction: row; }
    }
    ::v-deep .fair-product{
        &__head{
            font-size: 13px;

            .tit { line-height: 1; font-size: 16px; }
        }
        &__contents { margin: 0 -5px; }
        &__list { flex: 0 0 50%; padding: 25px 5px; width: 50%; font-size: 13px; }
        &__list-thumb { margin-bottom: 20px; }
        &__list-txt { font-size: 13px; }
        &__list-tit { font-size: 15px; }
    }
}
